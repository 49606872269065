import { createSlice } from '@reduxjs/toolkit';
import {
  editInventoryDetails,
  getInventoryDetails,
  getInventoryListing,
  postInventoryData,
  uploadFile
} from './inventory.api';
import { toast } from 'react-toastify';

export const inventoryFilterInitialValues = {
  limit: 10,
  page: 0,
  propertyType: '',
  transType: '',
  source: '',
  status: '',
  propertyCategory: ''
};
const inventorySlice = createSlice({
  initialState: {
    filters: inventoryFilterInitialValues,
    isLoading: false,
    currentTablePage: 0,
    rowPerPage: 10
  },
  name: 'inventory',
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const inventoryActions = inventorySlice.actions;

export const handleAddInventory = (params) => async (dispatch) => {
  try {
    dispatch(inventoryActions.setIsLoading(true));
    const data = await postInventoryData(params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(inventoryActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(inventoryActions.setIsLoading(false));
  }
};

export const handleGetInventoryDetails = (inventoryId) => async (dispatch) => {
  try {
    dispatch(inventoryActions.setIsLoading(true));
    const data = await getInventoryDetails(inventoryId);
    return data;
  } catch (error) {
    dispatch(inventoryActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(inventoryActions.setIsLoading(false));
  }
};

export const handleEditInventoryDetails = (id, params) => async (dispatch) => {
  try {
    dispatch(inventoryActions.setIsLoading(true));
    const data = await editInventoryDetails(id, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(inventoryActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(inventoryActions.setIsLoading(false));
  }
};

export const handleGetInventoryListing = (filters) => async (dispatch) => {
  try {
    dispatch(inventoryActions.setIsLoading(true));
    const data = await getInventoryListing(filters);
    return data;
  } catch (error) {
    dispatch(inventoryActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(inventoryActions.setIsLoading(false));
  }
};

export const handleUploadImage = (inventoryId, form) => async (dispatch) => {
  try {
    dispatch(inventoryActions.setIsLoading(true));
    const data = await uploadFile(inventoryId, form);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(inventoryActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(inventoryActions.setIsLoading(false));
  }
};

export default inventorySlice;
