import { createSlice } from '@reduxjs/toolkit';
import {
  addDigitalEcosystem,
  addPropertyComment,
  addPropertyDetails,
  deleteDoc,
  deleteFile,
  getDigitalEcosystem,
  getFiles,
  getLiveabilityDetails,
  getProperties,
  getPropertiesByClientId,
  getPropertyComments,
  getPropertyDetails,
  getPropertyVerifiedDetails,
  recommendBuyers,
  recommendProperty,
  reminderDoc,
  renameDoc,
  renewDoc,
  setCover,
  updatePropertyDetails,
  updatePropertyVerifiedDetails,
  uploadFile
} from 'api/property';
import { googlePlacesTextSearchLatLng } from 'api/thirdPartyApi';
import { toast } from 'react-toastify';

export const propertyFilterInitialValues = {
  branch: '',
  branchHead: '',
  channelPartner: '',
  createdFromDate: '',
  createdToDate: '',
  customerType: '',
  locality: '',
  maxProfileCompletion: '',
  minProfileCompletion: '',
  propertyStatus: '',
  referralType: '',
  rewm: '',
  rm: '',
  sortAt: '',
  sortBy: '',
  transactionType: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const propertySlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: propertyFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'property',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const propertyActions = propertySlice.actions;

export const handleGetProperties = (params) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await getProperties(params);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleGetPropertyDetails = (propertyId) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await getPropertyDetails(propertyId);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleGetPropertiesByClientId = (clientId, page, limit) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await getPropertiesByClientId(clientId, page, limit);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleAddPropertyDetails = (clientId, params) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await addPropertyDetails(clientId, params);
    if (data) {
      toast.success(data?.message || 'Property added successfully');
    }
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleUpdatePropertyDetails = (clientId, propertyId, params) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await updatePropertyDetails(clientId, propertyId, params);
    if (data) {
      toast.success(data?.message || 'Property updated successfully');
    }
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleGetPropertyComments = (id) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await getPropertyComments(id);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleAddPropertyComments = (id, comments) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await addPropertyComment(id, comments);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleFileUpload = (clientId, files) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await uploadFile(clientId, files);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleGetFiles = (clientId, propertyId) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await getFiles(clientId, propertyId);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleDeleteFile = (clientId, propertyId, fileId) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await deleteFile(clientId, propertyId, fileId);
    if (data?.msg) {
      toast.success('File deleted successfully!');
    }
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleGetLiveabilityDetails = (propertyId) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await getLiveabilityDetails(propertyId);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleGetPropertyVerifiedDetails = (propertyId) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await getPropertyVerifiedDetails(propertyId);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleUpdatePropertyVerifiedDetails = (propertyId, params) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await updatePropertyVerifiedDetails(propertyId, params);
    if (data) {
      toast.success(data?.message || 'Property updated successfully');
    }
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleSetCover = (propertyId, fileId) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await setCover(propertyId, fileId);
    if (data?.message) {
      toast.success('Image set as cover successfully!');
    }
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleDocRenewal = (clientId, params) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await renewDoc(clientId, params);
    if (data) {
      toast.success(data?.message || 'Property updated successfully');
    }
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleDocRename = (clientId, params) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await renameDoc(clientId, params);
    if (data) {
      toast.success(data?.message || 'File renamed successfully');
    }
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleDocReminder = (clientId, propertyId) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await reminderDoc(clientId, propertyId);

    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleDeleteDoc = (clientId, params) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await deleteDoc(clientId, params);
    if (data?.msg) {
      toast.success('File deleted successfully!');
    }
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleGetGooglePlacesDetails = (query) => async (dispatch) => {
  try {
    const data = await googlePlacesTextSearchLatLng(query);
    return data;
  } catch (error) {
    // dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleGetRecommendProperty = (orderId) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await recommendProperty(orderId);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleGetRecommendBuyers = (orderId) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await recommendBuyers(orderId);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleGetDigitalEcosystem = (propertyId) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await getDigitalEcosystem(propertyId);
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export const handleAddDigitalEcosystem = (propertyId, orderId, params) => async (dispatch) => {
  try {
    dispatch(propertyActions.setIsLoading(true));
    const data = await addDigitalEcosystem(propertyId, orderId, params);
    if (data) {
      toast.success(data?.message || 'Social Media URLs added successfully');
    }
    return data;
  } catch (error) {
    dispatch(propertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(propertyActions.setIsLoading(false));
  }
};

export default propertySlice;
