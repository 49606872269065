import LocalStorageService from 'utils/LocalStorageService';
import { axiosClient } from '../utils/axiosClient';

const localStorageService = LocalStorageService.getService();

const propertiesDropdownValuesEndpoint = 'v1/client/properties/dropdown/values';
const preferencesDropdownValuesEndpoint = 'v1/client/preferences/dropdown/values';
const profileDropdownValuesEndpoint = 'v1/client/profile/dropdown/values';
const productsCatalogValuesEndpoint = 'v1/products/catalog';
const trendingLocationsValuesEndpoint = 'v1/client/trending_locations';
const countriesDropdownValuesEndpoint = 'v1/client/profile/country';
const statesDropdownValuesEndpoint = 'v1/client/profile/state?';
const citiesDropdownValuesEndpoint = 'v1/client/profile/city?';
const leadsDropdownValuesEndpoint = 'v1/leads/dropdown/values';
const channelPartnersValuesEndpoint = 'v1/channel_partners';
const orderStatusValuesEndpoint = 'v2/client/order/status/dropdown/values';
const paymentDropdownValuesEndpoint = 'v1/users/payment/dropdown/values';
const eventDropdownValuesEndpoint = 'v1/event/dropdown/values';
const employeeDropdownValuesEndpoint = 'v1/employee/dropdown/values';
const channelPartnersSourcesDropdownValuesEndpoint = 'v2/channel_partner/dropdown/values';
const branchHeadDropdownValuesEndpoint = '/v1/employee/branch_heads';
const callbackDropdownValuesEndpoint = '/v2/call_back/dropdown/values';
const loanStatusDropdownValuesEndpoint = '/v1/loans/status/dropdown/values';
const leadManagersDropdownValuesEndpoint = '/v2/employee/lead_managers';
const brokerDropdownValuesEndpoint = '/v2/brokers/dropdown/values';

export const getPropertiesDropdownValues = () => {
  return axiosClient.get(propertiesDropdownValuesEndpoint).then((resp) => resp?.data);
};

export const getPreferencesDropdownValues = () => {
  return axiosClient.get(preferencesDropdownValuesEndpoint).then((resp) => resp?.data);
};

export const getProfileDropdownValues = () => {
  return axiosClient.get(profileDropdownValuesEndpoint).then((resp) => resp?.data);
};

export const getProductsCatalogValues = () => {
  return axiosClient.get(productsCatalogValuesEndpoint).then((resp) => resp?.data);
};

export const getTrendingLocationsValues = () => {
  return axiosClient.get(trendingLocationsValuesEndpoint).then((resp) => resp?.data);
};

export const getQuickAccessValues = () => {
  return axiosClient
    .get('v1/client/quick_access/values', {
      headers: {
        Authorization: `Bearer ${localStorageService.getAccessToken()}`
      }
    })
    .then((resp) => resp?.data);
};

export const getNeighbourhoodPreferencesDropdownValues = () => {
  return axiosClient.get('v1/client/preferences/neighbourhood/values').then((resp) => resp?.data);
};

export const getCountriesDropDownValues = () =>
  axiosClient.get(countriesDropdownValuesEndpoint).then((resp) => resp?.data);

export const getStatesDropDownValues = (countryCode) =>
  axiosClient
    .get(statesDropdownValuesEndpoint + `country_code=${countryCode}`)
    .then((resp) => resp?.data);

export const getCitiesDropDownValues = (countryCode, stateCode) =>
  axiosClient
    .get(citiesDropdownValuesEndpoint + `country_code=${countryCode}&state_code=${stateCode}`)
    .then((resp) => resp?.data);

export const getChannelPartnersDropdownValues = () =>
  axiosClient.get(channelPartnersValuesEndpoint).then((resp) => resp?.data);

export const getLeadsDropdownValues = () =>
  axiosClient.get(leadsDropdownValuesEndpoint).then((resp) => resp?.data);

export const getOrderStatusDropdownValues = () =>
  axiosClient.get(orderStatusValuesEndpoint).then((resp) => resp?.data);

export const getPaymentDropdownValues = () =>
  axiosClient.get(paymentDropdownValuesEndpoint).then((resp) => resp?.data);

export const getEventDropdownValues = () =>
  axiosClient.get(eventDropdownValuesEndpoint).then((resp) => resp?.data);

export const getEmployeeDropdownValues = () =>
  axiosClient.get(employeeDropdownValuesEndpoint).then((resp) => resp?.data);

export const getChannelPartnersSourcesDropdownValues = () =>
  axiosClient.get(channelPartnersSourcesDropdownValuesEndpoint).then((resp) => resp?.data);

export const getBranchHeadDropdownValues = () =>
  axiosClient.get(branchHeadDropdownValuesEndpoint).then((resp) => resp?.data);

export const getCallbackDropdownValues = () =>
  axiosClient.get(callbackDropdownValuesEndpoint).then((resp) => resp?.data);

export const getLoanStatusValues = () =>
  axiosClient.get(loanStatusDropdownValuesEndpoint).then((resp) => resp?.data);

export const getLeadManagersDropdownValues = () =>
  axiosClient.get(leadManagersDropdownValuesEndpoint).then((resp) => resp?.data);

export const getBrokerDropdownValues = () =>
  axiosClient.get(brokerDropdownValuesEndpoint).then((resp) => resp?.data);

export const getChannelPartnerVerticalValues = () =>
  axiosClient.get(`/v2/channel_partner_vertical_listing`).then((resp) => resp?.data);
