import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CircleIcon from '@mui/icons-material/Circle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { Avatar, Box, Button, Grid, InputAdornment, TextField, useMediaQuery } from '@mui/material';
import FaqModal from 'components/Faq';
import Loader from 'components/Loader';
import NotificationModal from 'components/NotificationModal';
import ResponsiveSideBar from 'components/ResponsiveSideBar';
import useDebounce from 'hooks/useDebounce';
import PropTypes from 'prop-types';
import { handleSearch } from 'store/mainSlice';
import { debounceDelay } from 'utils/constant';
import { COLORS } from 'utils/enums';
import ImagesLinks from 'utils/ImagesLinks';
import AvatarMenu from './components/AccountMenu';
import AddButtonDropdown from './components/AddButtonDropdown';
import SearchResult from './components/SearchResult';
import headerStyles from './Header.styles';

function Header({ hideBackButton = false, onBackClick }) {
  const navigate = useNavigate();
  const classes = headerStyles();
  const dispatch = useDispatch();
  const userAvatar = useSelector((state) => state?.auth?.userAvatar);
  const { pathname } = useLocation();

  const [isNotificationModalVisible, setIsNotificationModalVisible] = useState(false);
  const [accountMenu, setAccountMenu] = useState(null);
  const [addButtonDropdown, setAddButtonDropdown] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [isSearchResultModalVisible, setIsSearchResultModalVisible] = useState(false);
  const [isOpenFaq, setIsOpenFaq] = useState(false);
  const notifications = useSelector((state) => state.notification?.notifications);
  const notificationsCount = useSelector((state) => state.notification?.notificationsCount);
  const isLoading = useSelector((state) => state.notification?.isLoading);
  const isSmallScreen = useMediaQuery('(max-width: 1024px)');
  const debouncedInputValue = useDebounce(searchText, debounceDelay); // Debounce with 300ms delay

  useEffect(() => {
    getSearchResult(debouncedInputValue);
  }, [debouncedInputValue]);

  const getSearchResult = async (debouncedInputValue, limit = 2) => {
    if (debouncedInputValue) {
      const response = await dispatch(handleSearch(debouncedInputValue, limit));
      setSearchResult(response);
      setIsSearchResultModalVisible(true);
    } else {
      setSearchResult(null);
      setIsSearchResultModalVisible(false);
    }
  };

  return (
    <>
      {!isSmallScreen && (
        <Grid container className={classes.main}>
          <Grid item xs={3} lg={6} className={classes.leftHeaderSec}>
            {!hideBackButton && (
              <Button
                variant="text"
                className={classes.backButton}
                onClick={() => {
                  if (onBackClick) {
                    console.log('Navigating not');
                    onBackClick();
                  } else {
                    console.log('Navigating back');
                    navigate(-1);
                  }
                }}>
                <img
                  alt="back-button-arrow"
                  style={{
                    width: '24px'
                  }}
                  src={ImagesLinks.LeftArrow}
                />
              </Button>
            )}

            <Box sx={{ width: { lg: '100%' } }}>
              <TextField
                autoComplete="off"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className={classes.searchBar}
                sx={{
                  border: pathname.includes('/dashboard')
                    ? '1px solid rgba(10, 58, 103, 0.15)'
                    : 'none'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchSharpIcon
                        sx={{
                          color: 'rgba(10, 58, 103, 0.9)',
                          fontSize: '33px',
                          fontWeight: '400'
                        }}
                      />
                    </InputAdornment>
                  )
                }}
                placeholder="Search Clients, Properties, Leads etc."
              />
              {isSearchResultModalVisible ? (
                <SearchResult
                  setIsSearchResultModalVisible={setIsSearchResultModalVisible}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  searchResult={searchResult}
                />
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={6} lg={5} className={classes.rightHeaderSec}>
            <Button
              variant="contained"
              className={classes.addButton}
              onClick={(event) => setAddButtonDropdown(event.currentTarget)}
              sx={{
                backgroundColor: COLORS.PRIMARY,
                borderRadius: '10px',
                boxShadow: 'none !important',
                color: '#FFF',
                fontFamily: 'Lato',
                fontSize: '15px',
                fontWeight: '400',
                letterSpacing: '-0.02em',
                lineHeight: '20px',
                textTransform: 'capitalize'
              }}
              startIcon={<img alt="add-icon" width="24px" src={ImagesLinks.AddIcon} />}>
              {isSmallScreen ? null : 'Add'}
            </Button>
            <AddButtonDropdown
              open={Boolean(addButtonDropdown)}
              anchorEl={addButtonDropdown}
              handleClose={() => setAddButtonDropdown(null)}
            />
            <Box className={classes.notificationButton}>
              <Button
                sx={{
                  border: '1px solid rgba(10, 58, 103, 0.15)',
                  borderRadius: '8px',
                  height: '100%'
                }}
                variant="text"
                onClick={() => setIsNotificationModalVisible(true)}>
                <NotificationsIcon sx={{ color: '#0A3A67', fontSize: '2rem' }} />
              </Button>
              {notificationsCount > 0 && (
                <CircleIcon
                  sx={{
                    borderRadius: '50%',
                    boxSizing: 'border-box',
                    color: '#FF9153',
                    fontSize: '14px',
                    padding: 0,
                    position: 'absolute',
                    right: 16,
                    top: 14
                  }}
                />
              )}
            </Box>
            <Avatar
              className={classes.avatar}
              src={userAvatar || ''}
              onClick={(event) => setAccountMenu(event.currentTarget)}
            />
            <AvatarMenu
              open={Boolean(accountMenu)}
              anchorEl={accountMenu}
              handleClose={() => setAccountMenu(null)}
              setIsOpenFaq={setIsOpenFaq}
            />

            {isOpenFaq && <FaqModal isOpenFaq={isOpenFaq} setIsOpenFaq={setIsOpenFaq} />}

            {!isLoading && (
              <NotificationModal
                isNotificationModalVisible={isNotificationModalVisible}
                setIsNotificationModalVisible={setIsNotificationModalVisible}
                notifications={notifications}
                notificationsCount={notificationsCount}
              />
            )}
          </Grid>
        </Grid>
      )}

      {isSmallScreen && (
        <>
          <Box>
            <Grid
              container
              className={classes.main}
              sx={{ padding: pathname.startsWith('/dashboard') ? '0 1rem' : '0' }}>
              {!hideBackButton && (
                <Grid item xs={2} lg={6}>
                  <Button
                    fullWidth
                    variant="text"
                    className={classes.backButton}
                    onClick={() => navigate(-1)}>
                    <ArrowBackRoundedIcon
                      sx={{
                        background: '#0A3A67',
                        borderRadius: '50%',
                        color: 'white',
                        padding: '2px'
                      }}
                    />
                  </Button>
                </Grid>
              )}

              <Grid
                item
                xs={!hideBackButton ? 7.5 : 10}
                sx={{
                  '& .MuiInputBase-input': {
                    padding: '12px !important'
                  }
                }}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className={classes.searchBar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchSharpIcon
                          sx={{
                            color: 'rgba(10, 58, 103, 0.9)',
                            fontSize: '33px',
                            fontWeight: '400'
                          }}
                        />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search Clients, Properties, Leads etc."
                />
                {isSearchResultModalVisible ? (
                  <SearchResult
                    setIsSearchResultModalVisible={setIsSearchResultModalVisible}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    searchResult={searchResult}
                  />
                ) : null}
              </Grid>
              <Grid item xs={1.5} lg={5}>
                <ResponsiveSideBar />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: '1rem' }}>
            <Grid
              container
              sx={{ padding: pathname.startsWith('/dashboard') ? '0 1rem' : '0' }}
              className={classes.main}>
              <Grid item xs={4} lg={6} className={classes.leftHeaderSec}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.addButton}
                  onClick={(event) => setAddButtonDropdown(event.currentTarget)}
                  sx={{
                    backgroundColor: COLORS.PRIMARY,
                    borderRadius: '10px',
                    color: '#FFF',
                    fontFamily: 'Lato',
                    fontSize: '15px',
                    fontWeight: '400',
                    letterSpacing: '-0.02em',
                    lineHeight: '20px',
                    mr: '1rem',
                    textTransform: 'capitalize'
                  }}
                  startIcon={
                    <img
                      alt="add-icon"
                      width="24px"
                      src={ImagesLinks.AddIcon}
                      style={{ marginRight: '6px' }}
                    />
                  }>
                  Add
                </Button>
                <AddButtonDropdown
                  open={Boolean(addButtonDropdown)}
                  anchorEl={addButtonDropdown}
                  handleClose={() => setAddButtonDropdown(null)}
                />
              </Grid>
              <Grid item xs={6} lg={5} className={classes.rightHeaderSec}>
                <Box className={classes.notificationButton}>
                  <Button
                    sx={{
                      border: '1px solid rgba(10, 58, 103, 0.15)',
                      borderRadius: '8px',
                      height: '100%'
                    }}
                    variant="text"
                    onClick={() => setIsNotificationModalVisible(true)}>
                    <NotificationsIcon sx={{ color: '#0A3A67', fontSize: '2rem' }} />
                  </Button>
                  {notificationsCount > 0 && (
                    <CircleIcon
                      sx={{
                        borderRadius: '50%',
                        boxSizing: 'border-box',
                        color: '#FF9153',
                        fontSize: '14px',
                        padding: 0,
                        position: 'absolute',
                        right: 16,
                        top: 14
                      }}
                    />
                  )}
                </Box>
                {!isLoading && (
                  <NotificationModal
                    isNotificationModalVisible={isNotificationModalVisible}
                    setIsNotificationModalVisible={setIsNotificationModalVisible}
                    notifications={notifications}
                    notificationsCount={notificationsCount}
                  />
                )}
                <Avatar
                  className={classes.avatar}
                  src={userAvatar || ''}
                  onClick={(event) => setAccountMenu(event.currentTarget)}
                />
                <AvatarMenu
                  open={Boolean(accountMenu)}
                  anchorEl={accountMenu}
                  handleClose={() => setAccountMenu(null)}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {/* loader */}
      {isLoading && <Loader />}
    </>
  );
}

export default memo(Header);

Header.propTypes = {
  addButtonText: PropTypes.string,
  hideBackButton: PropTypes.bool
};
