import { axiosClient } from 'utils/axiosClient';

export const getInventoryListing = (filters) => {
  const {
    page = 0,
    limit = 10,
    propertyType,
    transType,
    source,
    status,
    propertyCategory
  } = filters;

  const params = {
    property_type: propertyType,
    property_category: propertyCategory,
    transaction_type: transType,
    limit,
    offset: limit * page,
    status: status,
    lead_source: source
  };

  const query = Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';
  return axiosClient.get(`v2/inventories${queryString}`).then((resp) => resp?.data);
};

export const postInventoryData = (payload) => {
  return axiosClient.post(`v2/inventory/add`, payload).then((resp) => resp?.data);
};

export const getInventoryDetails = (id) => {
  return axiosClient.get(`v2/inventory/details/${id}`).then((resp) => resp?.data);
};

export const editInventoryDetails = (id, payload) => {
  return axiosClient.put(`v2/inventory/${id}/update`, payload).then((resp) => resp?.data);
};

export const uploadFile = (id, form) => {
  return axiosClient.post(`v2/inventory/${id}/fileupload`, form).then((resp) => resp?.data);
};
