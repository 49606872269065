const ImagesLinks = {
  AQIBg: require('assets/images/AQI-Bg.png'),
  ActivePropertiesImage: require('assets/images/ActiveProperties.png'),
  AddAddressSample: require('assets/images/add_address_sample.png'),
  AddExisting: require('assets/images/add-existing.png'),
  AddIcon: require('assets/images/add-icon.png'),
  AddNew: require('assets/images/add-new.png'),
  AirQuality: require('assets/images/AirQuality.png'),
  Airport: require('assets/images/Airport.png'),
  Apartment: require('assets/images/Apartment.png'),
  AppDownloadRatioIcon: require('assets/images/AppDownloadRatioIcon.png'),
  AppDownloadsImage: require('assets/images/AppDownloads.png'),
  ApprovalPendingIcon: require('assets/images/ApprovalPendingIcon.png'),
  AreaImage: require('assets/images/Area.png'),
  ArrowIcon: require('assets/images/Vector-(1).png'),
  Awesome: require('assets/images/Awesome.png'),
  Bank: require('assets/images/Bank.png'),
  BathroomImage: require('assets/images/Bathroom.png'),
  BedroomImage: require('assets/images/Bedroom.png'),
  BellImage: require('assets/images/Vector-(1).png'),
  BgPattern: require('assets/images/bg-pattern.png'),
  BreakUpIcon: require('assets/images/BreakUpIcon.png'),
  BuildingImage: require('assets/images/webimg/squareyards.png'),
  Bus: require('assets/images/Bus.png'),
  CalendarImage: require('assets/images/calendar.png'),
  CardDesign: require('assets/images/CardDesign.png'),
  ChangeProfileImg: require('assets/images/ChangeProfileImg.svg'),
  ChannelPartner: require('assets/images/ChannelDetailsLogo.png'),
  ChannelPartnerPrompt: require('assets/images/ChannelPartnerPrompt.png'),
  ChannelPartnerRmPrompt: require('assets/images/ChannelPartnerRm.png'),
  ChannelPartnerSuccess: require('assets/images/ChannelPartnerSuccess.png'),
  ChannelPartnerSuccessRm: require('assets/images/ChannelPartnerRmSuccess.png'),
  Chart: require('assets/images/Chart.png'),
  ChartImage: require('assets/images/Vector-645.png'),
  ClassicCleaning: require('assets/images/ClassicCleaning.png'),
  ClientImage: require('assets/images/ClientImage.png'),
  CloseImage: require('assets/images/Closeimage.png'),
  CloseImageWhite: require('assets/images/CloseImageWhite.png'),
  CommercialImage: require('assets/images/addproperty/commercial.svg'),
  CommercialStudio: require('assets/images/CommercialStudio.png'),
  CommonFloorsLogo: require('assets/images/webimg/commonfloor.png'),
  ConfirmImg: require('assets/images/ConfirmImg.png'),
  Current: require('assets/images/Current.png'),
  CustomerImage: require('assets/images/Dashboardimg/customer.png'),
  DateImage: require('assets/images/Vector-(1).png'),
  DemoIcon: require('assets/images/Dashboardimg/customer.png'),
  DocImg: require('assets/images/DocImg.png'),
  DocumentIcon: require('assets/images/DocumentIcon.png'),
  Dotted: require('assets/images/Line-57.png'),
  EditIcon: require('assets/images/edit-icon.png'),
  EmiCalculator: require('assets/images/EmiCalculator.png'),
  EmptyBox: require('assets/images/EmptyBox.png'),
  EventsReport: require('assets/images/EventsReport.png'),
  GraphLine: require('assets/images/High.png'),
  GrenStockUpArrowImage: require('assets/images/GreenStockUpArrow.png'),
  GrowthImage: require('assets/images/growth.png'),
  GrowthImage1: require('assets/images/growth.png'),
  HomeImage: require('assets/images/Vector-(1).png'),
  Hospital: require('assets/images/Hospital.png'),
  HouseImage: require('assets/images/webimg/squareyards.png'),
  HoverVector: require('assets/images/HoverVector.png'),
  IALogoPropCoin: require('assets/images/IALogoPropCoin.png'),
  IAlogo: require('assets/images/Indiassetz.png'),
  ImageHotel: require('assets/images/Rectangle-807.png'),
  IndependentHouse: require('assets/images/IndependentHouse.png'),
  InternetProblem: require('assets/images/internet-problem.png'),
  LeftArrow: require('assets/images/Dashboardimg/leftarrow.png'),
  Line: require('assets/images/Line-56.png'),
  ListingsImage: require('assets/images/Dashboardimg/listings.png'),
  Loader: require('assets/images/loader.gif'),
  LoansIcon: require('assets/images/LoansIcon.svg'),
  LoginBg: require('assets/images/LoginBg.png'),
  LoginPageImage: require('assets/images/login-page-image.png'),
  LogoImage: require('assets/images/webimg/logo.svg'),
  LogoImageDrawer: require('assets/images/indiassetz-logo.png'),
  Logout: require('assets/images/Dashboardimg/exit.png'),
  MagicBricksLogo: require('assets/images/webimg/magicbricks.png'),
  MakaanLogo: require('assets/images/webimg/makaan.png'),
  MessageImage: require('assets/images/Message.png'),
  Metro: require('assets/images/Metro.png'),
  MobileImage: require('assets/images/mobile-pay.png'),
  MoneyImage: require('assets/images/Vector-(1).png'),
  MoneyImage1: require('assets/images/Group-48098007.png'),
  NeighbourhoodBg: require('assets/images/Neighbourhood-Bg.png'),
  NetWorthImage: require('assets/images/networth.png'),
  NoBrokersLogo: require('assets/images/webimg/nobrokers.png'),
  NoCommentIcon: require('assets/images/NoCommentIcon.png'),
  NoPropertyFound: require('assets/images/NoPropertyFound.png'),
  NotFoundImage: require('assets/images/NotFound.png'),
  Notification: require('assets/images/Dashboardimg/bell.png'),
  NumberOfClientsImage: require('assets/images/NumberOfClients.png'),
  Others: require('assets/images/Others.png'),
  Park: require('assets/images/Parks.png'),
  Partners: require('assets/images/Partners.png'),
  PdfIcon: require('assets/images/PDFImg.png'),
  PdfPlaceholder: require('assets/images/pdf-placeholder.png'),
  Penthouse: require('assets/images/Penthouse.png'),
  Plot: require('assets/images/Plot.png'),
  PlusIcon: require('assets/images/Vector-(Stroke).png'),
  PollutionLevel: require('assets/images/PollutionLevel.png'),
  ProfileCompletedImage: require('assets/images/profilecompleted.png'),
  ProfileImage: require('assets/images/profimg.png'),
  ProgressImage: require('assets/images/Chart.png'),
  PropCoinBg: require('assets/images/PropCoinBg.png'),
  PropCoinIcon: require('assets/images/PropCoinIcon.png'),
  PropCoinsImg: require('assets/images/PropCoinsImg.png'),
  PropertiesClientImage: require('assets/images/PropertiesClient.png'),
  PropertiesImage: require('assets/images/Dashboardimg/properties.png'),
  PropertyCustomerRatioIcon: require('assets/images/PropertyCustomerRatioIcon.png'),
  PropertyDetailsImage: require('assets/images/PropertyDetailsImage.png'),
  PropertyImage: require('assets/images/sidecardicons/Properties.svg'),
  PropertyImagesample: require('assets/images/propertysample.png'),
  RejectedIcon: require('assets/images/RejectedIcon.png'),
  RemoveProfileImg: require('assets/images/RemoveProfileImg.svg'),
  RequestedIcon: require('assets/images/RequestedIcon.png'),
  RequestsImage: require('assets/images/Dashboardimg/requests.png'),
  ResidentialImage: require('assets/images/addproperty/residential.svg'),
  Restaurants: require('assets/images/Restaurants.png'),
  RightArrowIcon: require('assets/images/right-arrow-icon.png'),
  Rowhouse: require('assets/images/Rowhouse.png'),
  SaleValueBg: require('assets/images/SaleValueBg.png'),
  School: require('assets/images/Schools.png'),
  SearchNotFoundImage: require('assets/images/SearchNotFound.png'),
  Shopping: require('assets/images/Shopping.png'),
  SpecialNoteAuthor1: require('assets/images/SpecialNoteAuthor1.png'),
  SpecialNoteAuthor2: require('assets/images/SpecialNoteAuthor2.png'),
  SpecialNoteAuthor3: require('assets/images/SpecialNoteAuthor3.png'),
  SpecialNoteAuthor4: require('assets/images/SpecialNoteAuthor4.png'),
  SpecialNoteAuthor5: require('assets/images/SpecialNoteAuthor5.png'),
  SquareYardsLogo: require('assets/images/webimg/squareyards.png'),
  StockUpArrowImage: require('assets/images/StockUpArrow.png'),
  StudioApartment: require('assets/images/StudioApartment.png'),
  SubscriptionRationIcon: require('assets/images/SubscriptionRationIcon.png'),
  SulekhaLogo: require('assets/images/webimg/sulekha.png'),
  TopNavImage: require('assets/images/main.png'),
  TotalAUMIcon: require('assets/images/TotalAUMIcon.png'),
  TotalPropertiesImage: require('assets/images/totalproperties.png'),
  TotalSubscriptionIcon: require('assets/images/TotalSubscriptionIcon.png'),
  TreeImage: require('assets/images/Group-48098008.png'),
  Triangle: require('assets/images/Triangle.png'),
  UploadProfileImg: require('assets/images/UploadProfileImg.svg'),
  UserImagesample: require('assets/images/userpicture.png'),
  UserPictureImage: require('assets/images/userpicture.png'),
  VectorPlusImage: require('assets/images/Vector-(1).png'),
  Verified: require('assets/images/verified.png'),
  VerifiedIcon: require('assets/images/VerifiedIcon.png'),
  VerifyIcon: require('assets/images/VerifyIcon.png'),
  WordPlaceholder: require('assets/images/word-placeholder.png'),
  doorsNewLogo: require('assets/images/doors-new-logo.png'),
  iaAi: require('assets/images/IA-AI.png'),
  keyPortfolioIndicators: {
    ClientIcon: require('assets/images/KeyPortfolioIndicators/Client.png'),
    PropertiesForRentIcon: require('assets/images/KeyPortfolioIndicators/PropertiesForRent.png'),
    PropertiesForSellIcon: require('assets/images/KeyPortfolioIndicators/PropertiesForSell.png'),
    TotalPropertiesIcon: require('assets/images/KeyPortfolioIndicators/TotalProperties.png')
  },
  liveNow: require('assets/images/Dashboardimg/live-now.png'),
  magikBricks: require('assets/images/magikbricks.png'),
  ninetyAcres: require('assets/images/nintyAcres.png'),
  prospectiveBuyersTemperature: {
    cold: require('assets/images/cold.png'),
    hot: require('assets/images/hot.png'),
    warm: require('assets/images/warm.png')
  },
  responseMIS: require('assets/images/reponseMIS.png'),
  revenueBookedIcons: {
    offline: require('assets/images/OfflinePaymentIcon.png'),
    online: require('assets/images/OnlinePaymentIcon.png'),
    total: require('assets/images/TotalPaymentIcon.png')
  },
  LinkedIn: require('assets/images/LinkedIn.png'),
  Youtube: require('assets/images/Youtube.png'),
  Facebook: require('assets/images/Facebook.png'),
  Insta: require('assets/images/Insta.png'),
  Google: require('assets/images/Google.png')
};

export default ImagesLinks;
