import { combineReducers } from '@reduxjs/toolkit';
import dashboardSlice from 'features/Dashboard/dashboard.slice';
import eventSlice from 'features/Events/event.slice';
import loanSlice from 'features/Loans/loans.slice';
import magikSlice from 'features/Magik/magik.slice';
import primarySalesMagikSlice from 'features/Magik/primarySalesMagik.slice';
import stMagikSlice from 'features/Magik/stMagik.slice';
import primarySalesBuilderSlice from 'features/PrimarySales/Builders/primarySalesBuilderSlice';
import primarySalesPropertySlice from 'features/PrimarySales/Properties/primarySalesPropertySlice';
import primarySalesTransOrderSlice from 'features/PrimarySales/TransactionalOrders/primarySalesTransOrderSlice';
import authSlice from './authSlice';
import billingAddressSlice from './billingAddressSlice';
import brokerSlice from './brokerSlice';
import callbackSlice from './callbackSlice';
import clientSlice from './clientSlice';
import dropdownSlice from './dropdownSlice';
import eKYCSlice from './eKYCSlice';
import leadSlice from './leadSlice';
import mainSlice from './mainSlice';
import notificationSlice from './notificationSlice';
import orderSlice from './orderSlice';
import primarySalesBillingAddressSlice from './primarySalesBillingAddressSlice';
import propertySlice from './propertySlice';
import recommendationSlice from './recommendationSlice';
import stBillingAddressSlice from './stBillingAddressSlice';
import stCasesSlice from './stCasesSlice';
import stClientSlice from './stClientSlice';
import stOrderSlice from './stOrderSlice';
import stPropertySlice from './stPropertySlice';
import transactionalOrderSlice from './transactionalOrderSlice';
import venderSlice from './venderSlice';
import vistaOrderSlice from './vistaSlice';
import loanLeadsSlice from '../features/Loans/LoanLeads/loanLeads.slice';
import inventorySlice from 'features/Inventory/inventory.slice';

const reducers = combineReducers({
  auth: authSlice.reducer,
  billingAddress: billingAddressSlice.reducer,
  broker: brokerSlice.reducer,
  callback: callbackSlice.reducer,
  client: clientSlice.reducer,
  dashboard: dashboardSlice.reducer,
  dropdown: dropdownSlice.reducer,
  ekyc: eKYCSlice.reducer,
  event: eventSlice.reducer,
  lead: leadSlice.reducer,
  loanLeads: loanLeadsSlice.reducer,
  loans: loanSlice.reducer,
  magik: magikSlice.reducer,
  main: mainSlice.reducer,
  notification: notificationSlice.reducer,
  order: orderSlice.reducer,
  primarySalesBillingAddress: primarySalesBillingAddressSlice.reducer,
  primarySalesBuilder: primarySalesBuilderSlice.reducer,
  primarySalesMagik: primarySalesMagikSlice,
  primarySalesProperty: primarySalesPropertySlice.reducer,
  primarySalesTransOrder: primarySalesTransOrderSlice.reducer,
  property: propertySlice.reducer,
  recommendation: recommendationSlice.reducer,
  stBillingAddress: stBillingAddressSlice.reducer,
  stCases: stCasesSlice.reducer,
  stClient: stClientSlice.reducer,
  stMagik: stMagikSlice.reducer,
  stOrder: stOrderSlice.reducer,
  stProperty: stPropertySlice.reducer,
  transactionalOrder: transactionalOrderSlice.reducer,
  vender: venderSlice.reducer,
  vista: vistaOrderSlice.reducer,
  inventory: inventorySlice.reducer
});

export default reducers;
