import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { default as loanIcon } from 'assets/images/LoansIcon.svg';
import { default as brokerIcon } from 'assets/images/sidecardicons/brokerIcon.svg';
import { default as prospectiveBuyers } from 'assets/images/sidecardicons/buyer.svg';
import { default as callbackIcon } from 'assets/images/sidecardicons/Callback.svg';
import { default as clientsIcon } from 'assets/images/sidecardicons/Clients.svg';
import { default as dashboardIcon } from 'assets/images/sidecardicons/Dashboard.svg';
import { default as leadsIcon } from 'assets/images/sidecardicons/Leads.svg';
import { default as magikIcon } from 'assets/images/sidecardicons/magik.svg';
import { default as ordersIcon } from 'assets/images/sidecardicons/Orders.svg';
import { default as primarySalesIcon } from 'assets/images/sidecardicons/PrimarySales.svg';
import { default as propertiesIcon } from 'assets/images/sidecardicons/Properties.svg';
import { default as stIcon } from 'assets/images/sidecardicons/stIcon.svg';
import { default as venderIcon } from 'assets/images/sidecardicons/venderIcon.svg';
import { default as inventoryIcon } from 'assets/images/sidecardicons/InventoryIcon.svg';

import { rolesEnums } from 'utils/enums';
import SideMenuBarStyles from './SideMenuBar.style';

export default function SideMenuBar() {
  const initialNotificationState = {
    buyerNotificationCount: 0,
    callbackNotificationCount: 0,
    clientNotificationCount: 0,
    leadNotificationCount: 0,
    orderNotificationCount: 0,
    primarySaleBuildersNotificationCount: 0,
    primarySalePropertiesNotificationCount: 0,
    propertyNotificationCount: 0,
    transactionalOrderNotificationCount: 0
  };

  const classes = SideMenuBarStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { notifications } = useSelector((state) => state?.notification);
  const userRole = useSelector((state) => state.auth?.role);

  const [notificationState, setNotificationState] = useState(initialNotificationState);
  const [navItemStates, setNavItemStates] = useState([]);
  const [collapseSubMenu, setCollapseSubMenu] = useState(null);

  const {
    clientNotificationCount,
    propertyNotificationCount,
    orderNotificationCount,
    leadNotificationCount,
    callbackNotificationCount,
    buyerNotificationCount,
    transactionalOrderNotificationCount,
    stClientsNotificationCount,
    stPropertiesNotificationCount,
    stCasesNotificationCount,
    primarySalePropertiesNotificationCount,
    primarySaleBuildersNotificationCount,
    primarySaleTransOrderNotificationCount
  } = notificationState;

  useEffect(() => {
    getRolesOnNavItems();
    setNotificationState(calculateNotificationCount(notifications));
  }, [notifications]);

  // change here for roles permission, add menu items and notifications
  const navItems = [
    {
      count: '',
      icon: dashboardIcon,
      name: 'Dashboard',
      url: '/dashboard',
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.CHANNEL_PARTNER,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: clientNotificationCount,
      icon: clientsIcon,
      name: 'Clients',
      url: '/clients',
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.CHANNEL_PARTNER,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: propertyNotificationCount,
      icon: propertiesIcon,
      name: 'Properties',
      url: '/properties',
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.CHANNEL_PARTNER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: orderNotificationCount + transactionalOrderNotificationCount,
      icon: ordersIcon,
      name: 'Orders',
      subMenu: [
        {
          count: transactionalOrderNotificationCount,
          icon: '',
          name: 'Exchanges',
          url: '/orders/transactional'
        },
        {
          count: '',
          icon: '',
          name: 'Yard',
          url: '/orders/non-transactional'
        },
        {
          count: '',
          icon: '',
          name: 'Vista',
          url: '/orders/vista'
        },
        {
          count: '',
          icon: '',
          name: 'Tax Assessment',
          url: '/orders/tax-assessment'
        }
      ],
      url: '',
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER,
        rolesEnums.REAL_ESTATE_WEALTH_MANAGER
      ]
    },
    {
      count: callbackNotificationCount,
      icon: callbackIcon,
      iconStyle: { height: '32px', width: '32px' },
      name: 'CallBacks',
      url: '/callbacks',
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: leadNotificationCount,
      icon: leadsIcon,
      iconStyle: { height: '32px', width: '32px' },
      name: 'Leads',
      url: '/leads',
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.CHANNEL_PARTNER,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: '',
      icon: magikIcon,
      iconStyle: { height: '32px', width: '32px' },
      name: 'MAGIK',
      url: '/magik',
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: buyerNotificationCount,
      icon: prospectiveBuyers,
      iconStyle: { height: '32px', width: '32px' },
      name: 'Buyers',
      url: '/prospective-buyers',
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: '',
      icon: stIcon,
      iconStyle: { height: '32px', width: '32px' },
      name: 'ST',
      subMenu: [
        {
          count: stClientsNotificationCount,
          icon: '',
          name: 'ST Clients',
          url: '/st/clients'
        },
        {
          count: stPropertiesNotificationCount,
          icon: '',
          name: 'ST Properties',
          url: '/st/properties'
        },
        {
          count: stCasesNotificationCount,
          icon: '',
          name: 'ST Exchanges',
          url: '/st/cases'
        },
        {
          count: stCasesNotificationCount,
          icon: '',
          name: 'ST Yard',
          url: '/st/yard'
        }
      ],
      url: '',
      visibleBasedOnRoles: [
        // rolesEnums.PORTFOLIO_MANAGER,
        // rolesEnums.RELATIONSHIP_MANAGER,
        // rolesEnums.SERVICE_COORDINATOR,
        // rolesEnums.CHANNEL_PARTNER,
        rolesEnums.SUPER_ADMIN
        // rolesEnums.ADMIN,
        // rolesEnums.BRANCH_HEAD,
        // rolesEnums.ST_PORTFOLIO_MANAGER,
        // rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: '',
      icon: primarySalesIcon,
      iconStyle: { height: '32px', width: '32px' },
      name: 'Primary Sales',
      subMenu: [
        {
          count: primarySaleBuildersNotificationCount,
          icon: '',
          name: 'Builders',
          url: '/primary-sales/builders'
        },
        {
          count: primarySalePropertiesNotificationCount,
          icon: '',
          name: 'Properties',
          url: '/primary-sales/properties'
        },
        {
          count: primarySaleTransOrderNotificationCount,
          icon: '',
          name: 'Transactional Orders',
          url: '/primary-sales/transactional-orders'
        },
        {
          count: '',
          icon: '',
          name: 'Buyers',
          url: '/primary-sales/prospective-buyers'
        }
      ],
      url: '',
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: '',
      icon: loanIcon,
      iconStyle: { height: '32px', width: '32px' },
      name: 'Loans',
      subMenu: [
        {
          count: '',
          icon: '',
          name: 'Leads',
          url: '/loan/leads'
        },
        {
          count: '',
          icon: '',
          name: 'Applications',
          url: '/loans'
        }
      ],
      url: '',
      visibleBasedOnRoles: [
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: '',
      icon: brokerIcon,
      name: 'Brokers',
      url: '/brokers',
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.CHANNEL_PARTNER,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: '',
      icon: venderIcon,
      name: 'Vendors',
      url: '/vendors',
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.CHANNEL_PARTNER,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    },
    {
      count: '',
      icon: inventoryIcon,
      name: 'Inventory',
      url: '/inventory',
      iconStyle: { height: '3rem', width: '3rem' },
      visibleBasedOnRoles: [
        rolesEnums.PORTFOLIO_MANAGER,
        rolesEnums.RELATIONSHIP_MANAGER,
        rolesEnums.SERVICE_COORDINATOR,
        rolesEnums.CHANNEL_PARTNER,
        rolesEnums.PS_PORTFOLIO_MANAGER,
        rolesEnums.PS_RELATIONSHIP_MANAGER,
        rolesEnums.SUPER_ADMIN,
        rolesEnums.ADMIN,
        rolesEnums.BRANCH_HEAD,
        rolesEnums.ST_PORTFOLIO_MANAGER,
        rolesEnums.ST_RELATIONSHIP_MANAGER
      ]
    }
  ];
  const getRolesOnNavItems = () => {
    setNavItemStates(navItems);
  };

  const handleClickNavigate = (url) => {
    navigate(url);
  };

  const handleClickSubMenu = (index) => {
    setCollapseSubMenu(index);

    if (index === collapseSubMenu) {
      setCollapseSubMenu(null);
    }
  };

  const calculateNotificationCount = (notifications) => {
    const notificationState = { ...initialNotificationState };

    if (notifications && notifications[0]) {
      const {
        clients,
        client_comments,
        property_comments,
        leads,
        call_backs,
        prospects,
        external_normal_order_comments,
        external_normal_order_sub_comments,
        external_transaction_order_comments,
        external_transaction_order_sub_comments,
        normal_order_comments,
        normal_orders,
        transaction_order_comments,
        transaction_orders
      } = notifications[0];

      // calculate client notification count
      notificationState.clientNotificationCount =
        (clients?.[0]?.notification_count || 0) + (client_comments?.[0]?.notification_count || 0);

      // calculate property notification count
      notificationState.propertyNotificationCount = property_comments?.[0]?.notification_count || 0;

      // calculate order notification count
      notificationState.orderNotificationCount =
        (external_normal_order_comments?.[0]?.notification_count || 0) +
        (external_normal_order_sub_comments?.[0]?.notification_count || 0) +
        (normal_order_comments?.[0]?.notification_count || 0) +
        (normal_orders?.[0]?.notification_count || 0);

      // calculate transactional notification count
      notificationState.transactionalOrderNotificationCount =
        (external_transaction_order_comments?.[0]?.notification_count || 0) +
        (external_transaction_order_sub_comments?.[0]?.notification_count || 0) +
        (transaction_order_comments?.[0]?.notification_count || 0) +
        (transaction_orders?.[0]?.notification_count || 0);

      // calculate lead notification count
      notificationState.leadNotificationCount = leads?.[0]?.notification_count || 0;

      // calculate callback notification count
      notificationState.callbackNotificationCount = call_backs?.[0]?.notification_count || 0;

      // calculate buyer notification count
      notificationState.buyerNotificationCount = prospects?.[0]?.notification_count || 0;
    }

    return notificationState;
  };

  return (
    <List
      sx={{ bgcolor: 'background.paper', maxWidth: 360, paddingTop: '0', width: '100%' }}
      component="nav"
      aria-labelledby="nested-list-subheader">
      {navItemStates.map((items, index) => {
        return (
          <>
            {items?.visibleBasedOnRoles?.includes(userRole) && (
              <>
                <ListItemButton
                  className={classes.boxButton}
                  key={items?.name}
                  sx={{
                    background: pathname.startsWith(
                      `/${items?.name.toLowerCase().replace(' ', '-')}`
                    )
                      ? '#E5EFF9'
                      : '#fff'
                  }}
                  onClick={() => {
                    if (items?.subMenu?.length) {
                      handleClickSubMenu(index);
                    } else {
                      handleClickNavigate(items?.url);
                    }
                  }}>
                  <ListItemIcon className={classes.iconBox}>
                    <img
                      src={items?.icon}
                      style={items.iconStyle || { height: '20px', width: '20px' }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={items?.name}
                    className={classes.sideBarText}
                    sx={{ color: '#0A3A67', fontWeight: '600' }}
                  />
                  {Boolean(Number(items.count)) && (
                    <Typography className={classes.notificationCountBox}>{items?.count}</Typography>
                  )}
                  {items?.subMenu?.length && (
                    <>
                      {index === collapseSubMenu ? (
                        <ExpandLess sx={{ color: '#757575' }} />
                      ) : (
                        <ExpandMore sx={{ color: '#757575' }} />
                      )}
                    </>
                  )}
                </ListItemButton>
              </>
            )}

            {items?.subMenu?.length && (
              <>
                <Collapse
                  in={index === collapseSubMenu ? true : false}
                  timeout="auto"
                  unmountOnExit>
                  <List component="div" disablePadding>
                    {items?.subMenu?.map((subItems) => {
                      return (
                        <>
                          <ListItemButton
                            onClick={() => handleClickNavigate(subItems?.url)}
                            className={classes.boxButton}
                            sx={{ pl: 4 }}>
                            <ListItemText
                              primary={subItems?.name}
                              className={classes.subSideBarText}
                              sx={{ color: '#0A3A67', fontWeight: '600' }}
                            />
                            {Boolean(Number(subItems?.count)) && (
                              <Typography className={classes.notificationCountBox}>
                                {subItems?.count}
                              </Typography>
                            )}
                          </ListItemButton>
                        </>
                      );
                    })}
                  </List>
                </Collapse>
              </>
            )}
          </>
        );
      })}
    </List>
  );
}
